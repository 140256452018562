@import "./fonts/noto-sans.scss";
@import "./fonts/noto-sans-georgian.scss";

img,
svg,
picture {
  display: block;
  max-width: 100%;
}

input {
  border-radius: 0;
  appearance: none;
}

@each $size in 6, 12, 16, 20, 24, 32, 36, 40, 48, 60, 65, 80, 100 {
  @each $d, $direction in "t" "top", "b" "bottom", "l" "left", "r" "right" {
    .m#{$d}-#{$size} {
      margin-#{$direction}: #{$size}px;
    }

    .p#{$d}-#{$size} {
      padding-#{$direction}: #{$size}px;
    }
  }

  .px-#{$size} {
    padding-right: #{$size}px;
    padding-left: #{$size}px;
  }

  .p-#{$size} {
    padding: #{$size}px;
  }
}
